import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getStoredUser as getStoredUserAction,
  showUserInfo as showUserInfoAction,
  logoutUser as logoutUserAction,
  getStoredUserBasket as getStoredUserBasketAction,
} from "../../redux/modules/login";
import useWindowDimensionsFunction from "../../hooks/useWindowDimensionsFunction";

function AppInfoLoader(props) {
  const navigate = useNavigate();
  let location = useLocation().key;

  function loadData() {
    props.getStoredUser();
    props.getStoredUserBasket();
  }

  useEffect(() => {
    loadData();
  }, [location]);

  useEffect(() => {
    if (props.login.user) {
      if (props.login.user.api_token && props.login.user.id) {
        props
          .showUserInfo(props.login.user.api_token, props.login.user.id)
          .then((val) => {
            if (val) {
            } else {
              props.logoutUser().then(() => {
                document.location.href = "/";
              });
            }
          });
      }
    }
  }, [props.login.user]);

 // useWindowDimensionsFunction(() => loadData());

  return null;
}

export default connect(({ login, category }) => ({ login, category }), {
  getStoredUser: getStoredUserAction,
  showUserInfo: showUserInfoAction,
  logoutUser: logoutUserAction,

  getStoredUserBasket: getStoredUserBasketAction,
  //getCategoryList: getCategoryListAction
})(AppInfoLoader);
