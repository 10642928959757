import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgCircle } from "../../assets/logo/circle.svg";
import SvgLogo from "../../assets/logo/logo.webp";

import {
  getStoredUser as getStoredUserAction,
   getStoredBasket as getStoredBasketAction
 } from "../../redux/modules/login";
import { getTopMenuCategoryList as getTopMenuCategoryListAction } from "../../redux/modules/category";
import { retrieveData, storeData } from "../../services/Storage";

import Container from "../container/Container";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiIcon from "../icon/UiIcon";
import UiMenuList from "../menu/UiMenuList";

import "./UiHeader.scss";

const UiHeader = (props) => {
  let navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [basket, setBasket] = useState([]);
  const [menuList, setMenuList] = useState([]);

  const setMenuOpen = () => {
    let new_v = !dropdownOpen;
    setDropdownOpen(new_v);
    props.onChangeMenu();
  };

  useEffect(() => {
    if (props.login.basketList) {
      setBasket(props.getStoredBasket());
    }
  }, [props.login.basketList]);

  useEffect(() => {
    props.getStoredUser().then((user) => {
      if (user) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    })
  
  }, []);

  useEffect(() => {
    setMenuList(retrieveData("menu_flowery") ? retrieveData("menu_flowery") : props.menuList1);
    props.getTopMenuCategoryList().then((res) => {
      let arr = res.map((c) => { 
        return {
          page: c.sub_list.length > 0 ? `/group/${c.title_url}`: `/catalog/${c.title_url}`,
          title: c.title,
          icon: c?.icon,
          //subList: c.sub_list.length > 0 ?  c.sub_list.map((i) =>{ return  { page:  `/catalog/${i.title_url}`, title: i.title } }) : null
          subList: null
        };
      });
      storeData("menu_flowery", arr);
      setMenuList(arr);
    })
  }, [])


  return (
    <header id="header" className="header-wrap">
      <div className="header-info-wrap">
        <Container>
          <div className="header-info">
            <a
              href="https://yandex.ru/maps/80/yuzhno-sakhalinsk/chain/flowery/177239590117/?ll=142.740275%2C46.954665&sll=142.740275%2C46.954656&z=13"
              className="header-info-item"
            >
              <UiIcon fill="pink" icon="pin" size={16} />
              <p>Южно-Сахалинск</p>
            </a>
            <div className="header-info-item">
              <UiIcon fill="pink" icon="clock" size={16} />
              <p>Круглосуточная доставка</p>
            </div>
            <div className="header-info-item">
              <UiIcon fill="pink" icon="checked-box" size={16} />
              <p>от 60 мин</p>
            </div>
            <div className="header-info-social">
              <div className="nav-menu-rating">
                <a href="/reviews" className="nav-menu-rating-btn">
                  <UiIcon icon="yandex" size={13} />
                  <span>4.9</span>
                </a>
                <a href="/reviews" className="nav-menu-rating-btn">
                  <UiIcon icon="google-g" size={14} />
                  <span>4.5</span>
                </a>
                <a href="/reviews" className="nav-menu-rating-btn">
                  <UiIcon icon="2gis" size={14} />
                  <span>4.4</span>
                </a>
              </div>
              <UiBtnIcon
                color=""
                fill="white"
                icon="whatsapp"
                iconSize={24}
                size={24}
                href="https://api.whatsapp.com/send/?phone=%2B79146400455&text&type=phone_number&app_absent=0"
              />
              <UiBtnIcon
                color=""
                fill="white"
                icon="telegram"
                iconSize={24}
                size={24}
                href="https://t.me/Flowerybot"
              />
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="header">
          <div className="header-main">
            <a className="btn header-logo" href="/">
              <img
                className="header-logo-icon"
                src={SvgLogo}
                height={72}
                width={237}
                title="Flowery"
                alt="Flowery"
              />
            </a>
            <div className="header-about">
              <p>Позвонить нам</p>
              <a className="header-about-phone" href="tel:88003334409">
                +7 (800) 333-44-09
              </a>
            </div>
          </div>
          <nav
            className={
              "nav-menu nav-menu-main" + (dropdownOpen ? " active" : "")
            }
          >
            <SvgCircle className="nav-menu-mobile-cover1" />
            <SvgCircle className="nav-menu-mobile-cover2" />
            <div className="nav-menu-mobile">
              <div className="nav-menu-mobile-wrap">
                <a className="btn header-logo" href="/">
                  <img
                    className="header-logo-icon"
                    src={SvgLogo}
                    height={138}
                    width={42}
                    title="Flowery"
                    alt="Flowery"
                  />
                </a>
                <UiBtnIcon
                  color="blue"
                  fill="white"
                  icon="close"
                  iconSize={12}
                  size={42}
                  onClick={() => setMenuOpen()}
                />
              </div>
            </div>
            <div>
              <div className="nav-menu-mobile">
                <UiMenuList menuList={props.menuList3} />
              </div>
              <UiMenuList menuList={props.menuList2} />
            </div>
            <div className="nav-menu-mobile">
              <div className="nav-menu-mobile-wrap">
                <div className="header-about">
                  <p>Позвонить нам</p>
                  <a className="header-about-phone" href="tel:88003334409">
                    +7 (800) 333-44-09
                  </a>
                </div>
                <div className="header-info-social">
                  <UiBtnIcon
                    color=""
                    fill="green"
                    icon="whatsapp"
                    iconSize={36}
                    size={36}
                  />
                  <UiBtnIcon
                    color=""
                    fill="blue"
                    icon="telegram"
                    iconSize={36}
                    size={36}
                  />
                </div>
              </div>
            </div>
          </nav>
          <div className="nav-menu-button">
            <UiBtnIcon
              color=""
              fill="pink"
              icon="menu"
              iconSize={20}
              size={48}
              onClick={() => setMenuOpen()}
            />
          </div>
        </div>
      </Container>
      <div
        className={
          "header-categories" +
          (props.stick ? " stick" : "") +
          (dropdownOpen ? " menu-open" : "")
        }
      >
        <Container>
          <nav className={"nav-menu nav-menu-categories"}>
            <UiMenuList allVisible menuList={menuList} />
            <div className={"header-profile" + (props.stick ? " stick" : "")}>
              <UiBtnIcon
                fill="pink"
                icon="search"
                iconSize={20}
                size={32}
                onClick={() => props.onSearchOpen()}
              />
              <UiBtnIcon
                fill={basket.length > 0 ? "white" : "pink"}
                color={basket.length > 0 ? "pink" : ""}
                icon="bag"
                iconSize={20}
                size={32}
                onClick={() => navigate("/order")}
              />
              <UiBtnIcon
                fill="pink"
                icon="user"
                iconSize={20}
                size={32}
                onClick={() =>
                  isLogin ? navigate("/profile") : navigate("/login")
                }
              />
            </div>
          </nav>
        </Container>
      </div>
    </header>
  );
};

export default connect(({ login }) => ({ login }), {
  getStoredUser: getStoredUserAction,
  getStoredBasket: getStoredBasketAction,
  getTopMenuCategoryList: getTopMenuCategoryListAction
})(UiHeader);
