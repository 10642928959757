import React, { useState, useEffect } from "react";

import UiIcon from "../icon/UiIcon";
import UiSelect from "../forms/UiSelect";
import StorageConstant from "../../constants/StorageConstant";

import "./UiCatalog.scss";
import UiTextInputSearch from "../forms/UiTextInputSearch";

const UiCatalogFilter = (props) => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [activeSize, setActiveSize] = useState(0);
  const [activePrice, setActivePrice] = useState(0);

  const filtersList = StorageConstant.TAGS_ARRAY_FILTER;
  const pricesList = [
    "Цена",
    "0₽ - 2000₽",
    "2000₽ - 3000₽",
    "3000₽ - 5000₽",
    "5000₽ - 10000₽",
    "более 10000₽",
  ];

  const sizesList = [
    "Все размеры",
    "S (20 - 30 см)",
    "M (30 - 40 см)",
    "L (40 - 50 см)",
    "XL (50 - 60 см)",
  ];

  useEffect(() => {
    if(props.activePrice){
      setActivePrice(props.activePrice);
    }
  }, [props.activePrice])

  return (
    <div className="catalog-filters">
      <div className="category-filters-select">
        <UiSelect
          onChange={(val) => {
            setActivePrice(val);
            props.onChangePrice(val);
          }}
          selected={pricesList[activePrice]}
          selectItems={pricesList}
          small
        />
        {/*<UiSelect
          onChange={(val) => {
            setActivePrice(val);
            props.onChangePrice(val);
          }}
          selected="Повод"
          selectItems={[]}
          small
        />
        <UiSelect
          onChange={(val) => {
            setActivePrice(val);
            props.onChangePrice(val);
          }}
          selected="Цветы"
          selectItems={[]}
          small
        />*/}
      </div>
    </div>
  );
};
export default UiCatalogFilter;
