import React from "react";
import Skeleton from "react-loading-skeleton";

import UiBtnIcon from "../buttons/UiBtnIcon";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiBarNum.scss";

const UiBarNum = (props) => {
  return (
    <div className={"product-add-buttons" + (props.small ? " small" : "")}>
      {props.loader ? (
        <Skeleton width={36} height={36} />
      ) : (
        <UiBtnIcon
          color={props.small ? "" : "blue-light"}
          fill={props.small ? "blue-light" : "black"}
          icon="remove"
          iconSize={10}
          size={36}
          round
          onClick={() => props.onMinus()}
        />
      )}
      {props.loader ? <Skeleton width={44} height={36} /> : <p>{props.num}</p>}
      {props.loader ? (
        <Skeleton width={36} height={36} />
      ) : (
        <UiBtnIcon
          color={props.small ? "" : "blue-light"}
          fill={props.small ? "blue-light" : "black"}
          icon="add"
          iconSize={10}
          size={36}
          round
          onClick={() => props.onPlus()}
        />
      )}
    </div>
  );
};
export default UiBarNum;
