import ENV from './Env.js';


export const getSettings = () => {
    return fetch(ENV.API_URL + '/settings/all', {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
}

 