import React from "react";
import { Helmet } from "react-helmet";

import UiPageTitle from "../../components/header/UiPageTitle";

import "../../styles/app.scss";

export default function PageContainer({ children, ...props }) {
  return (
    <div className={"page" + (props.page ? " " + props.page : "")}>
      <Helmet>
        <title>{props.pageTitle}</title>
        <meta name="description" content={props.pageDescription} />
        <meta name="keywords" content={props.pageKeywords ? props.pageKeywords : "цветы доставка Южно-Сахалинск цветы Южно-Сахалинск дёшево недорогой букет цветов Южно-Сахалинск букет на свадьбу Южно-Сахалинск купить букет на свадьбу Южно-Сахалинск свадебный букет срочно Южно-Сахалинск букет невесты Южно-Сахалинск купить свадебный букет Южно-Сахалинск свадебный букет Южно-Сахалинск доставка цветов Южно-Сахалинск доставка цветы Южно-Сахалинск розы Южно-Сахалинск эквадорские розы Южно-Сахалинск кустовые розы Южно-Сахалинск букет роз Южно-Сахалинск купить букет роз Южно-Сахалинск купить розы Южно-Сахалинск цветы Южно-Сахалинск купить цветы Южно-Сахалинск красивый букет Южно-Сахалинск купить красивый букет Южно-Сахалинск букет Южно-Сахалинск букет цветов Южно-Сахалинск цветы на заказ с доставкой Южно-Сахалинск магазины цветов Южно-Сахалинск"} />
      </Helmet>
      {props.linksList ? (
        <UiPageTitle linksList={props.linksList} pageName={props.pageName} />
      ) : null}
      {children}
    </div>
  );
}
