import React, { useEffect, useState } from "react";

import "./UiCheckList.scss";

const UiCheckList = (props) => {
  const [active, setActive] = useState(0);

  const setValue = (val) => {
    let new_val = val;
    setActive(new_val);
    props.onChange(new_val);
  };

  useEffect(() => {
    setActive(props.selected);
  }, [props.selected]);

  var checkList = props.checkList.map((item, index) => {
    return (
      <button
        key={index}
        className={
          "check-item" + 
          (active === index ? " active" : "") +
          (props.border ? " border" : "")
        }
        onClick={() => setValue(index)}
        style={item.option == "" ? { display: "none" } : null}
      >
        <div className={"radio-icon" + (active === index ? " active" : "")}>
          {active === index ? <div className="radio-icon-active"></div> : null}
        </div>
        {item.option && item.option !== "" ? <p>{item.option}</p> : null}
        {item.optionInfo && <span>{item.optionInfo}</span>}
      </button>
    );
  });
  return (
   
    <div className={"check-list" + (props.colored ? " colored" : "")}> 
      {props.label ? <p>{props.label}</p> : null}
      {checkList}
    </div>
  );
};
export default UiCheckList;
