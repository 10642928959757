import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UiBtnColor from "../../../components/buttons/UiBtnColor";
import UiIcon from "../../../components/icon/UiIcon";
import Env from "../../../services/Env";

import "./UiHomeSpecialSlider.scss";

const UiHomeSpecialSlider = (props) => {
  const navigate = useNavigate();

  const [activeSlide, setActiveSlide] = useState(0);
  const [prevSlide, setPrevSlide] = useState(null);
  const [nextSlide, setNextSlide] = useState(null); 

  useEffect(() => {
    if (props.slideList.length > 2) {
      setPrevSlide(props.slideList.length - 1);
      setNextSlide(activeSlide + 1);
    }
  }, []);

  const changeSlide = (val) => {

    let el = document.getElementsByClassName("home-title-slide");
    for (var item of el) {  item.classList.remove("hide"); }

    let direction = val;

    let current = activeSlide;
    let prev = prevSlide;
    let next = nextSlide;

    if (direction === "prev") {
      if (current > 0) {
        current = current - 1;
      } else {
        current = props.slideList.length - 1;
      }
      if (props.slideList.length > 2) {
        if (prev > 0) {
          prev = prev - 1;
        } else {
          prev = props.slideList.length - 1;
        }
        if (next > 0) {
          next = next - 1;
        } else {
          next = props.slideList.length - 1;
        }
      }
    }
    if (direction === "next") {
      if (current < props.slideList.length - 1) {
        current = current + 1;
      } else {
        current = 0;
      }
      if (props.slideList.length > 2) {
        if (prev < props.slideList.length - 1) {
          prev = prev + 1;
        } else {
          prev = 0;
        }
        if (next < props.slideList.length - 1) {
          next = next + 1;
        } else {
          next = 0;
        }
      }
    }
    setActiveSlide(current);
    setPrevSlide(prev);
    setNextSlide(next);
  };

  useEffect(() => {
 
    const timer = setInterval(() => {
      changeSlide("next");
    }, 3000);
    return () => clearInterval(timer);

  }, [props.slideList, activeSlide]);

  useEffect(() => {
    let el = document.getElementsByClassName("home-title-slide");
 
      setTimeout(() => {
        for (var item of el) { 
          if(window.getComputedStyle(item).opacity == 0) item.classList.add("hide");
        }
      }, 500)
   
  }, [activeSlide]);


  var slides = props.slideList.map((item, index) => {
    return (
      <a
        key={index}
        className={
          "home-title-slide" +
          (index === activeSlide ? " active" : "") +
          (index === prevSlide ? " prev" : "") +
          (index === nextSlide ? " next" : "")
        }
        
        href={(item.link && (item.title == null ||  item.title == "") ) && item.link }
      >
        <div
          className="home-title-slide-wrap"
          style={{
            backgroundImage: "url(" + Env.PUBLIC_URL + item.image + ")",
          }}
        >
          {item.title && (
            <div className="home-title-slide-title">
              <p>{item.title}</p>
            </div>
          )}
          {item.text && (
            <div className="home-title-slide-info">
              <p>{item.text}</p>
            </div>
          )}
          
        </div>
      </a>
    );
  });

  var dots = props.slideList.map((item, index) => {
    return (
      <button
        key={index}
        className={
          "home-title-slider-dot" + (index === activeSlide ? " active" : "")
        }
      ></button>
    );
  });

  return (
    <div className="home-title-slider">
      {slides}
      {props.slideList.length > 1 && (
        <div className="home-title-slider-arrow arrow-left">
          <button
            className="home-title-slider-arrow-btn"
            onClick={() => changeSlide("prev")}
          >
            <UiIcon fill="white" icon="arrow-left" size={20} />
          </button>
        </div>
      )}
      {props.slideList.length > 1 && (
        <div className="home-title-slider-arrow arrow-right">
          <button
            className="home-title-slider-arrow-btn"
            onClick={() => changeSlide("next")}
          >
            <UiIcon fill="white" icon="arrow-right" size={20} />
          </button>
        </div>
      )}
      {props.slideList.length > 1 && (
        <div className="home-title-slider-dots">{dots}</div>
      )}
    </div>
  );
};
export default UiHomeSpecialSlider;
