import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { searchLine as searchLineAction } from "../../redux/modules/storage";

import { convertToList } from "../../utilities/storage";

import Container from "../container/Container";
import UiBtnColor from "../buttons/UiBtnColor";
import UiCatalogCard from "../cards/UiCatalogCard";
import UiModal from "./UiModal";
import UiTextInputSearch from "../forms/UiTextInputSearch";

import "./UiSearch.scss";

const UiSearch = (props) => {
  const navigate = useNavigate();
  const [resultList, setResultList] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");

  if (resultList.length > 0) {
    var results = resultList.map((item, index) => {
      return (
        <UiCatalogCard
          key={index}
          image={item.image}
          price={item.price}
          search={true}
          sizeList={item.sizeList}
          status={item.status}
          title={item.title}
          onClick={() => {
            props.modalClose();
            navigate(`/product/${item.title_url}`);
          }}
        />
      );
    });
  }

  /**
   * clicks outside of ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.modalClose();
          //alert("You clicked outside of ref!");
        }
      }
      // bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <UiModal full={true} scroll={true} show={props.modalOpen}>
      <div ref={wrapperRef} className="modal-search">
        <Container>
          <div className="modal-search-title">
            <h2>Поиск по букетам</h2>
            <UiBtnColor
              color="white"
              onClick={() => props.modalClose()}
              small={true}
              text="Отмена"
            />
          </div>
          <div className="modal-search-line">
            <UiTextInputSearch
              placeholder="Найти.."
              onChange={(line) => {
                if (line.length > 0) {
                  props.searchLine(line).then((res) => {
                    if (res.response) {
                      let _count = res.response.length;
                      setResultList(
                        convertToList(
                          res.response
                            .filter((item) => {
                              return item.category != 7;
                            })
                            .splice(0, 6)
                        )
                      );
                      setCount(_count - 6);
                      setSearch(line);
                    }
                  });
                } else {
                  setResultList([]);
                  setCount(0);
                }
              }}
            />
          </div>
          <div className="modal-search-result">
            {resultList.length > 0 ? (
              <div className="modal-search-result-list">{results}</div>
            ) : (
              <div className="modal-search-result-empty">
                <p>Введите новый поисковый запрос</p>
              </div>
            )}
          </div>

          {count > 0 && (
            <UiBtnColor
              color="blue"
              small={true}
              text={`Смотреть все результаты (${count} шт)`}
              onClick={() => {
                props.modalClose();
                navigate(`/search/${search}`);
              }}
            />
          )}
        </Container>
      </div>
    </UiModal>
  );
};

export default connect(({ storage }) => ({ storage }), {
  searchLine: searchLineAction,
})(UiSearch);
