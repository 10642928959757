import React from "react";

import gift1 from "../../assets/demo/_DSF4910.jpeg";
import gift2 from "../../assets/demo/_DSF4886.jpeg";
import gift3 from "../../assets/demo/_DSF4907.jpeg";
import gift4 from "../../assets/demo/photo-163e76.jpg";

import "./UiAllGiftsCard.scss";

const UiAllGiftsCard = (props) => {
  return (
    <div className="all-gifts">
      <div className="all-gifts-card">
        <div
          className="all-gifts-card-image"
          style={{ backgroundImage: "url(" + gift1 + ")" }}
        />
        <h3>Записка к букету</h3>
        <p>Фирменная визитка-открытка с пожеланием от вас</p>
      </div>
      <div className="all-gifts-card">
        <div
          className="all-gifts-card-image"
          style={{ backgroundImage: "url(" + gift2 + ")" }}
        />
        <h3>Инструкция к букету</h3>
        <p>К букету прилагаются инструкция по уходу за цветами</p>
      </div>
      <div className="all-gifts-card">
        <div
          className="all-gifts-card-image"
          style={{ backgroundImage: "url(" + gift3 + ")" }}
        />
        <h3>Подкормка для цветов</h3>
        <p>Средство, продлевающее жизнь букета и его свежий вид</p>
      </div>
      <div className="all-gifts-card">
        <div
          className="all-gifts-card-image"
          style={{ backgroundImage: "url(" + gift4 + ")" }}
        />
        <h3>Фирменная упаковка</h3>
        <p>Подберем сочетающуюся с цветами упаковку и ленту</p>
      </div>
    </div>
  );
};
export default UiAllGiftsCard;
