import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { addFastOrder as addFastOrderAction } from "../../redux/modules/order";
import { getStoredUser as getStoredUserAction } from "../../redux/modules/login";

import { ReactComponent as SvgLogo } from "../../assets/logo/logo.svg";
import { ReactComponent as SvgFlower1 } from "../../assets/card/flower1.svg";
import { ReactComponent as SvgFlower2 } from "../../assets/card/flower2.svg";
import { ReactComponent as SvgFlowerSm1 } from "../../assets/card/flower-sm1.svg";
import { ReactComponent as SvgFlowerSm2 } from "../../assets/card/flower-sm2.svg";
import { ReactComponent as SvgImageStep2 } from "../../assets/card/image-option1.svg";
import { ReactComponent as SvgImageStep2Bg } from "../../assets/card/image-option1-bg.svg";
import { ReactComponent as SvgImageStep3 } from "../../assets/card/image-option2.svg";
import { ReactComponent as SvgImageStep3Bg } from "../../assets/card/image-option2-bg.svg";
import { ReactComponent as SvgImageTitle } from "../../assets/card/image-title.svg";
import { ReactComponent as SvgImageTitleBg } from "../../assets/card/image-title-bg.svg";
import { ReactComponent as SvgRound1 } from "../../assets/card/round0.svg";
import { ReactComponent as SvgRound2 } from "../../assets/card/round1.svg";
import { ReactComponent as SvgRound3 } from "../../assets/card/round2.svg";
import { ReactComponent as SvgQuotes } from "../../assets/card/quotes.svg";
import { ReactComponent as SvgTelegram } from "../../assets/social/telegram-fill.svg";
import { ReactComponent as SvgVk } from "../../assets/social/vk.svg";
import { ReactComponent as SvgWhatsapp } from "../../assets/social/whatsapp-fill.svg";

import UiBtnColor from "../buttons/UiBtnColor";
import UiModal from "./UiModal";

import "./UiGreetingCard.scss";
import { storeData } from "../../services/Storage";

const UiFlower = (props) => {
  const [animationDelay, setAnimationDelay] = useState("0s");
  const [fl1Size, setFl1Size] = useState("20px");
  const [fl2Size, setFl2Size] = useState("30px");
  const [flType, setFlType] = useState(null);

  const generateSnowflake = () => {
    const newDelay = `${(Math.random() * 16).toFixed(2)}s`;
    const newFl1Size = `${Math.floor(Math.random() * 10) + 40}px`;
    const newFl2Size = `${Math.floor(Math.random() * 10) + 60}px`;
    const newFlType = Math.floor(Math.random() * 2);
    setAnimationDelay(newDelay);
    setFl1Size(newFl1Size);
    setFl2Size(newFl2Size);
    setFlType(newFlType);
  };

  useEffect(() => {
    generateSnowflake();
  }, []);

  const style = { animationDelay };
  const styleFl1 = { width: fl1Size, height: fl1Size };
  const styleFl2 = { width: fl2Size, height: fl2Size };

  return (
    <div className="flower" id={`item${props.id}`} style={style}>
      {flType == 0 && <SvgFlowerSm1 className="flower-icon" style={styleFl1} />}
      {flType == 1 && <SvgFlowerSm2 className="flower-icon" style={styleFl2} />}
    </div>
  );
};

const UiFlowerRain = (props) => {
  const [numFlakes, setNumFlakes] = useState(32);

  const arr = new Array(numFlakes).fill("");
  const snow = arr.map((el, i) => {
    return <UiFlower key={i} id={i} />;
  });
  return <div className="flower-rain">{snow}</div>;
};

const UiGreetingCardBg = (props) => {
  return (
    <div className="greeting-card-bg">
      <SvgFlower1 className="greeting-card-bg-flower1" />
      <SvgFlower2 className="greeting-card-bg-flower2" />
    </div>
  );
};

const UiGreetingCardType = (props) => {
  return (
    <div
      className="modal-greeting-step2-option"
      onClick={() => props.onClick()}
    >
      <div className="modal-greeting-step2-image-wrap">
        {props.type === 0 ? (
          <SvgRound2 className="modal-greeting-step2-image1" />
        ) : (
          <SvgRound3 className="modal-greeting-step2-image1" />
        )}
        {props.type === 0 ? (
          <SvgImageStep2Bg className="modal-greeting-step2-image3" />
        ) : (
          <SvgImageStep3Bg className="modal-greeting-step2-image3" />
        )}
        {props.type === 0 ? (
          <SvgImageStep2 className="modal-greeting-step2-image2" />
        ) : (
          <SvgImageStep3 className="modal-greeting-step2-image2" />
        )}
      </div>
      <h3>{props.type === 0 ? "для бабушки" : "для мамы"}</h3>
    </div>
  );
};

const UiGreetingButton = (props) => {
  const [active, setActive] = useState(false);
  const setBtnActive = () => {
    setActive(!active);
    props.onChange();
  };
  return (
    <button className={active ? "active" : ""} onClick={() => setBtnActive()}>
      {props.text}
    </button>
  );
};

const UiGreetingCard = (props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [type, setType] = useState(null);
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [opt1, setOpt1] = useState([]);
  const [opt2, setOpt2] = useState([]);
  const [opt3, setOpt3] = useState([]);
  const [opt4, setOpt4] = useState([]);

  const optList1Arr = ["Любимая", "Дорогая", "Самая лучшая"];
  const optList2Arr = [
    "Добрая",
    "Заботливая",
    "Теплая",
    "Нежная",
    "Радостная",
    "Красивая",
    "Замечательная",
  ];
  const optList3Arr = [
    "Понимание и поддержку",
    "Жизненный опыт",
    "Домашнюю готовку",
    "Неиссякаемый оптимизм",
    "Безусловную любовь",
  ];
  const optList4Arr = [
    "Красота",
    "Здоровье",
    "Счастье",
    "Удача",
    "Семья",
    "Работа и карьера",
  ];

  const st1List = [
    "",
    "Сегодня особенный день - День Матери!",
    "Поздравляю тебя с Днем Матери!",
    "Сегодня праздник крепких нервов и выдержки.",
  ];
  const st2List = [];
  const st3List = [];
  const st4List = ["", "Такая мама нужна каждому!", "Ты лучшая из лучших!"];

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function getRandomText(number, numQuestion) {
    const dateArr = [
      [
        [
          "Любимая",
          "Любимая мамочка, с праздником Дня матери! Ты - самое дорогое и близкое мне в этом мире. ",
          "Любимая, надеюсь, ты не забыла, что я всегда буду твоим непосредственным наследником",
          "Любимая мамочка, поздравляю с Днем матери!",
          "Любимая мама, ты самая лучшая в мире, хотя иногда и ломаешь мне нервы своими вопросами о домашних делах. ",
        ],
        [
          "Дорогая",
          "Ты, дорогая Мама, особенная и неповторимая! ",
          "Дорогая мама, ты всегда была для меня опорой и поддержкой, и я благодарен за твою заботу и любовь. ",
          "Дорогая мама, твои нежные объятия всегда наполняют меня теплом и любовью, как будто я находился под солнечными лучами. ",
          "Дорогая мама, твоя нежность и радость всегда были моими опорами в жизни. ",
        ],
        [
          "Самая лучшая",
          "Самая лучшая Мама, с праздником Дня матери! ",
          "С днем матери, самая лучшая! ",
          "Мама, ты самая лучшая поддержка в моей жизни, даже если иногда твои советы звучат как черный юмор. ",
          "Мама, с Днем матери! Ты самая лучшая в мире, хотя иногда твои шутки - чернее моего юмора. ",
        ],
        [""],
        [""],
      ],
      [
        [
          " У тебя самое большое сердце, способное вместить в себя весь мир.",
          " Твоя доброта и забота не знают границ, они всегда рядом, когда мне нужно.",
          " Добрая мамочка, спасибо за то, что всегда готова помочь, даже если это означает, что я должен отложить свои планы на вечер.",
          " Ты добрая и заботливая, как никто другой, особенно когда дело касается моей домашней кухни.",
          " Ты добрая, даже когда я забываю сказать «спасибо» за свои непослушания.",
        ],
        [
          " Мой дом там, где ты.",
          " Ты - наша заботливая мама, которая всегда поддерживает и окружает нас своей любовью.",
          " Заботливая мама, твои заботы иногда превращаются в назойливость, но я знаю, что это все делается с любовью.",
          " Ты добрая и заботливая, как никто другой, особенно когда дело касается моей домашней кухни.",
          " Ты заботливая, даже когда я возвращаюсь домой с грязными носками и голодной животиной.",
        ],
        [
          " Твоя улыбка теплее любого свитера.",
          " Ты наш самый теплый, любимый и заботливый человек в жизни.",
          " Теплая мамуля, твои обнимашки всегда согревают меня, даже когда на улице мороз.",
          " Твоя теплая улыбка и красивая душа всегда согревают меня в любых ситуациях.",
          " Ты теплая, даже когда на улице холодно, а у меня нет шапки.",
        ],
        [
          " Твоя забота о нас - это самое теплое одеяло, под которым мы прячемся от всех бед.",
          " Ты всегда была и остаешься самым нежным, заботливым и любящим человеком в нашей жизни.",
          " Твоя нежность и забота обо мне будут продолжаться вечно.",
          " Ты нежная и замечательная мама, которую я люблю безмерно, даже когда ты снова говоришь мне свои оптимистичные шутки.",
          " Ты нежная, даже когда мои объятия - не самые ласковые.",
        ],
        [
          " Ты умеешь сделать нашу жизнь яркой и радостной, как никто другой.",
          " Ты - самая радостная и заботливая мама в мире!",
          " Не знаю, как ты справляешься с таким количеством детей, но твой радостный оптимизм просто зашкаливает.",
          " Ты всегда была для меня радостью и поддержкой, даже когда я делал шалости.",
          " Ты радостная, даже когда я просыпаюсь в пять утра и начинаю петь весьма натурально.",
        ],
        [
          " В мире так много прекрасного, но самое прекрасное это ты.",
          " Ты самая красивая и любимая на свете!",
          " Красивая мама, ты всегда выглядишь безупречно, даже когда твоя улыбка приходится на самые неподходящие ситуации. С Днем матери!",
          " Твоя красивая душа всегда согревают меня в любых ситуациях.",
          " Ты красивая, даже когда тебе некогда накраситься, потому что ты всегда выглядишь безупречно в моих глазах. Спасибо, что ты моя мама!"
        ],
        [
          " Я не лучший из детей, но ты самая лучшая мама.",
          " Ты самая замечательная женщина в мире, С тобой всегда счастливa и спокойна.",
          " Замечательная мама.",
          " Спасибо за всю твою любовь и за то, что ты - моя неподражаемая мама!",
          " Замечательная мама, ты всегда находишь способы поддержать меня, даже когда я шучу на грани черного юмора.",
        ],
      ],
      [
        [
          " Ты моя супер героиня, которая всегда приходит на помощь.",
          " Ты всегда понимаешь меня лучше, чем я сама, и твоя поддержка помогает мне преодолевать любые трудности.",
          " Дорогая мама, сегодня в День матери хочу поздравить тебя с твоими невероятными навыками понимания и поддержки – ведь только ты можешь выслушать все мои проблемы и найти решение.",
          " Понимающая мама, ты всегда знаешь, когда мне нужно сказать что-то совсем неприличное, и все равно меня любишь.",
          " Ты понимающая, и это суперсила, которой я всегда восхищаюсь.",
        ],
        [
          " Твоя мудрость - это мое вдохновение.",
          " Твой жизненный опыт и мудрость неподражаемы, и мы бесконечно гордимся тобой.",
          " Твой жизненный опыт и мудрость всегда помогают мне преодолевать любые трудности и добиваться своих целей.",
          " Спасибо за свой бесценный жизненный опыт, который помог мне достичь счастья и карьеры.",
          " Твой замечательный жизненный опыт всегда помогает мне в трудных ситуациях",
        ],
        [
          " Твои борщи самые вкусные.",
          " Твоя домашняя готовка - это то, что мы любим больше всего. Спасибо тебе за твои вкусные блюда и твою любовь.",
          " Ты – настоящий шеф-повар в нашей семье, и каждый раз, когда я попробую твою домашнюю готовку.",
          " Твоя домашняя готовка – настоящее чудо, ведь только она способна превратить скучный обед в настоящий праздник желудка.",
          " Твои кулинарные шедевры заставляют меня радоваться каждому приему пищи."
        ],
        [
          " Ты всегда знаешь, как поднять мне настроение.",
          " Желаю тебе неиссякаемого оптимизма, чтобы каждый день наполнялся радостью и счастьем.",
          " Твой радостный оптимизм просто зашкаливает.",
          " Твой оптимизм и улыбка всегда поднимают настроение и заставляют верить в лучшее.",
          " Твой оптимизм и любовь к жизни вселяют в меня веру в себя и светлое будущее.",
        ],
        [
          " Ты как солнце согреваешь меня своей любовью.",
          " Желаю тебе безусловной любви и счастья каждый день!",
          " Благодаря тебе я умею ценить безусловную любовь и заботу о семье.",
          " Ты – моя любовь, без тебя мир был бы серым и скучным.",
          " Любовь мамы - это когда она смеется над твоим черным юмором, даже если остальные не понимают.",
        ],
      ],
      [
        [
          " И я желаю тебе оставайся красива, словно роза.",
          " Ты - воплощение красоты и гармонии, как внешней, так и внутренней. Спасибо, что ты моя мама!",
          " И как ты всегда выглядишь так красиво, будто только что вышла из салона, несмотря на хаос в нашей семье?",
          " Ты прекрасна как внутри, так и снаружи, и я благодарен за твою красоту, которую передала мне в наследство.",
          " Ты красивая не только внешне, но и внутри, где твоя доброта и забота о семье сияют ярче всех косметических средств."
        ],
        [
          " Не болей, болеть не модно.",
          " Желаю тебе крепкого здоровья и счастья.",
          " Желаю тебе крепкого здоровья, чтобы продолжать радовать нас своим присутствием еще много-много лет. Спасибо, что ты моя замечательная мама!",
          " Желаю тебе крепкого здоровья, чтобы ты всегда была рядом и радовала своей заботой.",
          " Я желаю тебе крепкого здоровья, чтобы ты всегда была рядом со мной и моей семьей.",
        ],
        [
          " Пусть твой путь освещает фантастическое счастье.",
          " Пусть счастье и успех всегда сопутствуют, пусть твоя жизнь будет полна радости и любви.",
          " Это же просто счастье быть твоим ребенком!",
          " Желаю тебе радостных моментов и счастья в каждый день твоей жизни.",
          " Спасибо за свой бесценный жизненный опыт, который помог мне достичь счастья и карьеры.",
        ],
        [
          " Пусть восторг с удачей просто подерутся за тебя.",
          " Желаю тебе удачи во всем, что ты делаешь. Люблю тебя!",
          " Я понимаю, какой удачей я обладаю.",
          " Пусть удача всегда сопутствует тебе, мама, во всех начинаниях и жизненных приключениях!",
          " Ты удачливая, потому что иметь такую замечательную маму - это настоящая удача.",
        ],
        [
          " Пусть дом наполнится улыбками, смехом, посуда помоется сама.",
          " Спасибо за то, что ты создала такую прекрасную семью. Люблю тебя!",
          " Спасибо, что делаешь нашу семью такой счастливой и теплой! С днем матери!",
          " Желаю тебе крепкого здоровья, чтобы продолжать нас баловать своими вкусняшками и давать советы, которые мы все так любим.",
          " Ты семейная, и твоя любовь к нам делает нашу жизнь полной счастья и гармонии.",
        ],
        [
          " Все планы осуществятся и всегда шуршит в кармане.",
          " Твоя работа и карьера всегда вдохновляли меня на достижение своих целей. Спасибо за пример и любовь!",
          " Пусть твоя карьера продолжает расцветать, а я буду гордиться тобой и поддерживать во всех твоих начинаниях.",
          " Теперь я готов к любым вызовам, ведь я вырос под твоим заботливым крылом.",
          " Пусть твоя карьера будет успешной и приносить тебе удовлетворение, но помни, что для меня ты всегда будешь в первую очередь мамой.",
        ],
      ],
      [[""], ["Такая мама нужна каждому!"], ["Ты лучшая из лучших!"]],
    ];
    let _r = getRandomInt(0, dateArr[number][numQuestion].length - 1);
    // if(_r > dateArr[number][numQuestion].length) _r = dateArr[number][numQuestion].length -1;

    console.log(number, numQuestion, _r, dateArr[number][numQuestion]);
    return dateArr[number][numQuestion][_r];
  }

  function textGenerator() {
    let _result = "";
    let _type = `${type == 0 ? "бабушка" : "мама"}`;

    /** 1 OPTION LIST */
    var _text1 = "";

    for (let i = 0; i < opt1.length; i++) {
      _text1 = _text1 + getRandomText(0, opt1[i]);
    }

    _text1 =
      _text1 + " моя " + _type + `${name !== "" ? " " + name : ""}` + "! ";

    let _text1Static =
      " " + st1List[Math.floor(Math.random() * (st1List.length - 1))] + " ";

    /** 2 OPTION LIST */
    let _text2 = "";
    for (let i = 0; i < opt2.length; i++) {
      _text2 = _text2 + getRandomText(1, opt2[i]);
    }

    //let _text2Static =
    //  " " + st2List[Math.floor(Math.random() * (st2List.length - 1))] + " ";

    /** 3 OPTION LIST */
    let _text3 = "";
    for (let i = 0; i < opt3.length; i++) {
      _text3 = _text3 + getRandomText(2, opt3[i]);
    }

    /** 4 OPTION LIST */
    let _text4 = "";

    for (let i = 0; i < opt4.length; i++) {
      _text4 = _text4 + getRandomText(3, opt4[i]);
    }

    let _text4Static =
      " " + st4List[Math.floor(Math.random() * (st4List.length - 1))] + " ";

    let _text5Static = "С Днем Матери, дорогая " + _type + "!";

    /** RESULT */
    _result =
      _result +
      _text1 +
      // _text1Static +
      _text2 +
      // _text2Static +
      _text3 +
      _text4 +
      _text4Static +
      _text5Static;

    return _result;
  }

  const nextSlide = () => {
    if (activeSlide < 3) {
      let _num = activeSlide + 1;
      setActiveSlide(_num);
    }
  };

  const prevSlide = () => {
    if (activeSlide > 0) {
      let _num = activeSlide - 1;
      setActiveSlide(_num);
    }
  };

  function changeOptArr(num, val) {
    var _num = num;
    var _val = val;
    var _arr = [];

    if (_num == 1) {
      _arr = opt1;
      if (_arr.includes(_val)) {
        let ii = 0;
        _arr.map((item, index) => {
          if (item == _val) ii = index;
        });
        _arr.splice(ii, 1);
      } else {
        _arr.push(_val);
      }
      setOpt1(_arr);
    }

    if (_num == 2) {
      _arr = opt2;
      if (_arr.includes(_val)) {
        let ii = 0;
        _arr.map((item, index) => {
          if (item == _val) ii = index;
        });
        _arr.splice(ii, 1);
      } else {
        _arr.push(_val);
      }
      setOpt2(_arr);
    }

    if (_num == 3) {
      _arr = opt3;
      if (_arr.includes(_val)) {
        let ii = 0;
        _arr.map((item, index) => {
          if (item == _val) ii = index;
        });
        _arr.splice(ii, 1);
      } else {
        _arr.push(_val);
      }
      setOpt3(_arr);
    }

    if (_num == 4) {
      _arr = opt4;
      if (_arr.includes(_val)) {
        let ii = 0;
        _arr.map((item, index) => {
          if (item == _val) ii = index;
        });
        _arr.splice(ii, 1);
      } else {
        _arr.push(_val);
      }
      setOpt4(_arr);
    }
  }

  var optList1 = optList1Arr.map((item, index) => {
    return (
      <UiGreetingButton
        key={index}
        onChange={() => changeOptArr(1, index)}
        text={item}
      />
    );
  });
  var optList2 = optList2Arr.map((item, index) => {
    return (
      <UiGreetingButton
        key={index}
        onChange={() => changeOptArr(2, index)}
        text={item}
      />
    );
  });
  var optList3 = optList3Arr.map((item, index) => {
    return (
      <UiGreetingButton
        key={index}
        onChange={() => changeOptArr(3, index)}
        text={item}
      />
    );
  });
  var optList4 = optList4Arr.map((item, index) => {
    return (
      <UiGreetingButton
        key={index}
        onChange={() => changeOptArr(4, index)}
        text={item}
      />
    );
  });

  const optFormsList = [
    { text: "какая она", list: optList1 },
    { text: "какой у нее характер", list: optList2 },
    { text: "что вы цените в ней больше всего", list: optList3 },
    { text: "что для нее главное в жизни", list: optList4 },
  ];
  var listForms = optFormsList.map((item, index) => {
    return (
      <div key={index} className="modal-greeting-slide-form">
        <label>{item.text}</label>
        <div className="modal-greeting-slide-form-btns">{item.list}</div>
      </div>
    );
  });

  return (
    <UiModal full={true} overflow={true} scroll={false} show={props.modalOpen}>
      <Helmet>
        <title>
          Генератор поздравлений - Создай открытку на день матери для любимого
          человека
        </title>
        <meta
          name="description"
          content="генератор поздравлений - Создай открытку на день матери
для любимого человека"
        />
      </Helmet>

      <div className="modal-greeting">
        <UiFlowerRain />
        <div className="modal-greeting-head">
          <SvgLogo height={42} width={136} />
          <UiBtnColor
            color="light"
            onClick={() => props.modalClose()}
            small={true}
            text="Отмена"
          />
        </div>
        {/** SLIDE 1 */}
        <div
          className={
            "modal-greeting-slide" + (activeSlide === 0 ? " active" : "")
          }
        >
          <div className="modal-greeting-content">
            <div className="modal-greeting-step1-image-wrap">
              <SvgRound1 className="modal-greeting-step1-image1" />
              <SvgImageTitleBg className="modal-greeting-step1-image3" />
              <SvgImageTitle className="modal-greeting-step1-image2" />
            </div>
          </div>
          <div className="modal-greeting-button">
            <UiBtnColor
              color="pink"
              onClick={() => nextSlide()}
              style={{
                height: 56,
                padding: "0 64px",
                borderRadius: 20,
                fontSize: 14,
                fontWeight: "bold",
              }}
              text="Начать"
            />
          </div>
          <div className="modal-greeting-title-text">
            <h2>генератор поздравлений</h2>
            <h3>
              Создай открытку на день матери <br />
              для любимого человека
            </h3>
          </div>
        </div>
        {/** SLIDE 2 */}
        <div
          className={
            "modal-greeting-slide" + (activeSlide === 1 ? " active" : "")
          }
        >
          <UiGreetingCardBg />
          <div className="modal-greeting-slide-text">
            <h2>с днем матери</h2>
            <h3>Для кого вы составляете поздравление?</h3>
          </div>
          <div className="modal-greeting-step2-options">
            <UiGreetingCardType
              type={0}
              onClick={() => {
                nextSlide();
                setType(0);
              }}
            />
            <UiGreetingCardType
              type={1}
              onClick={() => {
                nextSlide();
                setType(1);
              }}
            />
          </div>
        </div>
        {/** SLIDE 3 */}
        <div
          className={
            "modal-greeting-slide" + (activeSlide === 2 ? " active" : "")
          }
        >
          <UiGreetingCardBg />
          <div className="modal-greeting-slide-scroll">
            <h2>собери идеальное поздравление</h2>
            <p>
              Выберите пункты, которые больше всего подходят для вашей мамы, и
              нажмите "Создать поздравление". <br />
              Старайтесь не выбирать больше одного или двух пунктов, отметьте
              самое важное для более лаконичного результата.
            </p>
            <div className="modal-greeting-slide-form">
              <label>имя {type === 0 ? "бабушки" : "мамы"}</label>

              <input
                value={name}
                onChange={(val) => {
                  setName(val.target.value);
                }}
              />
              <span>
                * для обращение по имени (например, бабушка Нина) не обязательно
                заполнять
              </span>
            </div>
            {listForms}
            <div className="modal-greeting-button">
              <UiBtnColor
                color="pink"
                onClick={() => {
                  nextSlide();
                  let _t = textGenerator();
                  setText(_t);
                }}
                style={{
                  height: 56,
                  padding: "0 64px",
                  borderRadius: 20,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                text="Создать поздравление"
              />
            </div>
          </div>
        </div>
        {/** SLIDE 4 */}
        <div
          className={
            "modal-greeting-slide" + (activeSlide === 3 ? " active" : "")
          }
        >
          <div className="modal-greeting-result">
            <div className="modal-greeting-slide-text">
              <h2>Поздравление готово!</h2>
            </div>
            <div className="modal-greeting-card">
              <SvgQuotes className="modal-greeting-card-quotes1" />
              <p>{text}</p>
              <SvgQuotes className="modal-greeting-card-quotes2" />
            </div>
            <div className="modal-greeting-card-send-msg">
              <h3>Отправьте поздравление online</h3>
              <div className="modal-greeting-card-send-msg-btns">
                <button
                  className="modal-greeting-card-send-msg-btn vk"
                  onClick={() => {
                    window.open(
                      "https://vk.com/share.php?url=https://flowery-lavka.ru/promo&title=Поздравление с днем матери",
                      "_blank"
                    );
                  }}
                >
                  <SvgVk className="modal-greeting-card-send-msg-icon" />
                </button>
                <button
                  className="modal-greeting-card-send-msg-btn tg"
                  onClick={() => {
                    window.open("tg://msg?text=" + text, "_blank");
                  }}
                >
                  <SvgTelegram className="modal-greeting-card-send-msg-icon" />
                </button>
                <button
                  className="modal-greeting-card-send-msg-btn wh"
                  onClick={() => {
                    window.open("https://wa.me/?text=" + text, "_blank");
                  }}
                >
                  <SvgWhatsapp className="modal-greeting-card-send-msg-icon" />
                </button>
              </div>
            </div>
            <div className="modal-greeting-button">
              <UiBtnColor
                color="green"
                onClick={() => {
                  storeData("mother_day_text", text);
                  window.open(
                    "https://flowery-lavka.ru/catalog/den-materi",
                    "_blank"
                  );
                }}
                style={{
                  height: 56,
                  padding: "0 64px",
                  borderRadius: 20,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                text="Заказать открытку с поздравлением!"
              />
            </div>
            <div className="modal-greeting-button">
              <UiBtnColor
                color="pink"
                onClick={() => setActiveSlide(0)}
                style={{
                  height: 56,
                  padding: "0 64px",
                  borderRadius: 20,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                text="Создать новое поздравление"
              />
            </div>
          </div>
        </div>
      </div>
    </UiModal>
  );
};

export default connect(({ login, order }) => ({ login, order }), {
  addFastOrder: addFastOrderAction,
  getStoredUser: getStoredUserAction,
})(UiGreetingCard);
