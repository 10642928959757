import APP from "../constants/AppConstant";

export const saveUser = (_user) => {
  localStorage.setItem(`${APP.APP_NAME_EN}_user`, JSON.stringify(_user) );
}

export const loadUser = (_user) => {
 let user = localStorage.getItem(`${APP.APP_NAME_EN}_user`);
 if(user != null && user != undefined) user = JSON.parse(user); 
 return user;
}

export const saveLocation = (_user) => {
  localStorage.setItem(`${APP.APP_NAME_EN}_user_location`, JSON.stringify(_user) );
}

export const loadLocation = (_user) => {
 let user = localStorage.getItem(`${APP.APP_NAME_EN}_user_location`);
 if(user != null && user != undefined) user = JSON.parse(user); 
 return user;
}


export const getUID = () => {
  let user = localStorage.getItem(`${APP.APP_NAME_EN}_user`);
  if(user != null && user != undefined) {
    user = JSON.parse(user); 
    return user.id;
  } else {
    return null;
  }
};


export const isAuthenticated = () => {
  if (localStorage.getItem(`${APP.APP_NAME_EN}_user`) == null) {
    return false;
  } else {
    return true;
  }
};

export const isOrg = () => {
  let user = localStorage.getItem(`${APP.APP_NAME_EN}_user`);
  if(user != null && user != undefined) {
    user = JSON.parse(user); 
    if (user.user_form_id == 1 || user.user_form_id == 2) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
 
};


export const logout = () => {
  if (localStorage.getItem(`${APP.APP_NAME_EN}_user`) != null) {
    localStorage.removeItem(`${APP.APP_NAME_EN}_user`);
  }
};
