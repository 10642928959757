import login from './login';
import material from './material';
import order from './order';
import storage from './storage'; 
import setting from './setting'; 

export default {
    login,
    material,
    order,
    storage, 
    setting,
}