import Env from "../services/Env";
import { convertImages } from "./array";

import demo1 from "../assets/demo/demo5.webp";

export const getImage = (img) => {
  let imgs = convertImages(img);
  return imgs.length > 0 ? Env.PUBLIC_URL + imgs[0] : demo1;
};



export const convertToYandexEcom = (_arr) => {
  let arr = [];
  _arr.map((item) => {
    arr.push({
      id: item.id, 
      name: item.title, 
      price: item.price, 
      quantity: 1, 
    });
  });

 
  return arr;
};


export const convertToList = (_arr) => {
  let arr = [];
  _arr.map((item) => {
    let imgs = convertImages(item.imgs);
    arr.push({
      id: item.id,
      image: imgs.length > 0 ? Env.PUBLIC_URL + imgs[0] : demo1,
      title: item.title,
      title_url: item.title_url,
      price: item.price,
      status: item.tags ? item.tags.split(",") : [],
      sale: item.sale,
      count: 1,
      loader: item.price ? false : true,
    });
  });

  //shake duplicates
  arr.map((item, index) => {
    if (arr[index + 1]) {
      let tr1 = item.title.split(" ");
      let tr2 = arr[index + 1].title.split(" ");
      tr1.splice(tr1.length - 1, 1);
      tr1 = tr1.join(" ");
      tr2.splice(tr2.length - 1, 1);
      tr2 = tr2.join(" ");
        if (tr1 == tr2) {
          let temp = arr[index + 1];
          let ind = (index%2 == 0 ?  arr.length - index : arr.length + index)
          if (arr[ind]) { 
            arr[index + 1] = arr[ind];
            arr[ind] = temp;
          } else  if (arr[index + 2])  {
            arr[index + 1] = arr[index + 2];
            arr[index + 2] = temp;
          }
        }
     
    }
  });

  return arr;
};

export const reorderBasket = (_arr) => {
  let arr = [];
  _arr.map((item) => {
    let f = -1;
    arr.map((item2, index2) => {
      if (item.title == item2.title) f = index2;
    });
    if (f == -1) {
      item.count = 1;
      arr.push(item);
    } else {
      arr[f].count++;
    }
  });
  return arr;
};

export const increaseBasket = (_arr, _id, _v) => {
  let f = -1;
  _arr.map((item, index) => {
    if (item.id == _id) item.count = _v;
    if (item.id == _id && _v == 0) f = index;
    if (item.id == _id && item.count == 0) f = index;
  });
  if (f !== -1) _arr.splice(f, 1);

  return _arr;
};
