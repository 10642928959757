import * as React from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";

import { ReactComponent as SvgLogoWhite } from "./assets/logo/circle-white.svg";

import AppInfoLoader from "./components/main/AppInfoLoader";
import AppModalComponent from "./components/main/AppModalComponent";

import UiBtnColor from "./components/buttons/UiBtnColor";
import UiBtnIcon from "./components/buttons/UiBtnIcon";
import UiBtnTopScroll from "./components/buttons/UiBtnTopScroll";
import UiIcon from "./components/icon/UiIcon";
import UiHeader from "./components/header/UiHeader";
import UiFooter from "./components/footer/UiFooter";
import UiQuiz from "./components/modals/UiQuiz";
import UiSearch from "./components/modals/UiSearch";

import { useWindowDimensions } from "./hooks/useWindowDimensions";
import AppNavigator from "./navigation/AppNavigator";

import configureStore from "./redux/store";

import "./styles/app.scss";
import UiGreetingCard from "./components/modals/UiGreetingCard";
import UiPromoAlertCard from "./components/modals/UiPromoAlertCard";

function App() {
  let navigate = useNavigate();

  const store = configureStore();
  const { width, height } = useWindowDimensions();
  const sliderH = height - 72;

  const [greetingOpen, setGreetingOpen] = useState(false);
  const [alertGreetingOpen, setAlertGreetingOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalQuizOpen, setModalQuizOpen] = useState(false);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const [headerStick, setHeaderStick] = useState(false);
  const [quizBtnShow, setQuizBtnShow] = useState(false);
  const [topBtnActive, setTopBtnActive] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollRef = useRef(null);

  let location = useLocation().pathname;

  const handleScroll = () => {
    const position = window.pageYOffset;
    //console.log(window.pageYOffset);
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    if (!location.includes("filter")) executeScroll();
    if (location.includes("quiz")) setModalQuizOpen(true);
    if (location.includes("promo")) setGreetingOpen(true);

    if (location == "/") {
      //setTimeout(() => { if(location == "/") setAlertGreetingOpen(true) }, 2000)
    }
  }, [location]);

  useEffect(() => {
    if (scrollPosition > 160) {
      setTopBtnActive(true);
      setHeaderStick(true);
    } else {
      setTopBtnActive(false);
      setHeaderStick(false);
    }

    if (scrollPosition > 420) {
      setQuizBtnShow(true);
    } else {
      setQuizBtnShow(false);
    }
  }, [scrollPosition]);

  useEffect(() => {}, []);

  const setMenuOpen = () => {
    let new_v = !rightPanelOpen;
    setRightPanelOpen(new_v);
  };

  const executeScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const smoothScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const menuList1 = [
    {
      page: "/group/rozy",
      title: "Розы",
      subList: [],
    },
    { page: "/catalog/sbornye-bukety", title: "Сборные Букеты" },
    { page: "/catalog/mono-bukety", title: "Монобукеты" },
    { page: "/catalog/shlyapnye-korobki", title: "Шляпные Коробки" },

    { page: "/catalog/svadebnye", title: "Свадебные" },
    { page: "/catalog/vypusknoy", title: "Выпускной" },
    { page: "/catalog/piony", title: "Пионы" },

    { page: "/catalog/podarki", title: "Подарки" },
  ];
  const menuList2 = [
    { page: "/delivery", title: "Доставка и оплата" },
    { page: "/contacts", title: "Контакты" },
    { page: "/about", title: "О нас" },
  ];
  const menuList3 = [
    { page: "/subscribe", title: "Подписка на цветы" },
    { page: "/vacancies", title: "Вакансии" },
  ];

  return (
    <Provider store={store}>
      <AppInfoLoader />
      <div className="app" id="app">
        <div className="app-scroll">
          <UiHeader
            menuList1={menuList1}
            menuList2={menuList2}
            menuList3={menuList3}
            onChangeMenu={() => setMenuOpen()}
            onSearchOpen={() => {
              setSearchOpen(true);
            }}
            stick={headerStick}
          />
          <AppNavigator />
          <UiFooter
            menuList={menuList1}
            menuList2={menuList2}
            menuList3={menuList3}
          />
        </div>
        <div id="fixedElem" className="app-msgs">
          <button className="app-msgs-btn">
            <UiIcon fill="white" icon="chat" size={20} />
          </button>
          <div className="app-msgs-list">
            <UiBtnIcon
              color="whatsapp"
              onClick={() => {
                window.ym(92961160, "reachGoal", "go-wa");
                window.location.href =
                  "https://api.whatsapp.com/send/?phone=%2B79146400455&text&type=phone_number&app_absent=0";
              }}
              fill="white"
              icon="whatsapp"
              iconSize={24}
              round
              size={36}
            />
            <UiBtnIcon
              color="telegram"
              onClick={() => {
                window.ym(92961160, "reachGoal", "go-inst");
                window.location.href = "https://t.me/Flowerybot";
              }}
              fill="white"
              icon="telegram"
              iconSize={24}
              round
              size={36}
            />
            <UiBtnIcon
              color="instagram"
              onClick={() => {
                window.ym(92961160, "reachGoal", "go-tg");
                window.location.href =
                  "https://instagram.com/flowery_sakhalin/";
              }}
              fill="white"
              icon="instagram"
              iconSize={24}
              round
              size={36}
            />
          </div>
        </div>
        <div className="mobile-basket-wrap">
          <button className="mobile-basket" onClick={() => navigate("/order")}>
            <UiIcon fill="white" icon="bag" size={20} />
          </button>
        </div>
        <div className={"quiz-button-wrap" + (quizBtnShow ? " active" : "")}>
          <button
            onClick={() => setModalQuizOpen(true)}
            className="btn quiz-button"
          >
            <SvgLogoWhite className="quiz-button-icon" />
            <span>Подобрать букет</span>
          </button>
        </div>
        <UiBtnTopScroll active={topBtnActive} onClick={() => smoothScroll()} />
        <UiQuiz
          modalOpen={modalQuizOpen}
          modalClose={() => {
            setModalQuizOpen(false);
            if (location.includes("quiz")) window.location.href = "/";
          }}
        />
        <UiSearch
          modalOpen={searchOpen}
          modalClose={() => {
            setSearchOpen(false);
          }}
        />
        {/** modals */}
        <AppModalComponent />

        <UiPromoAlertCard
          modalOpen={alertGreetingOpen}
          modalClose={() => setAlertGreetingOpen(false)}
        />
        <UiGreetingCard
          modalOpen={greetingOpen}
          modalClose={() => setGreetingOpen(false)}
        />
      </div>
    </Provider>
  );
}

export default App;
