import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeLoaderState as changeLoaderStateAction
} from "../../redux/modules/login" 

import UiLoader from "../../components/modals/UiLoader";
import UiAlert from "../../components/modals/UiAlert";

function AppModalComponent(props) {
  if (props.login.alertShowed) {
    return (
      <div className="modal-wrap">
        <UiAlert modalOpen={true} title="Заголовок сообщения" text="Текст всплывающего сообщения" />
      </div>
    )

  }
  if (props.login.loaderShowed) {
    return (
      <div className="modal-wrap">
        <UiLoader modalOpen={true} />
      </div>)
  }
}

export default connect(
  ({ login }) => ({ login }),
  {
    changeLoaderState: changeLoaderStateAction
  }
)(AppModalComponent);

