import React from "react";
import { Route, Navigate } from "react-router-dom";
import { storeData } from "../../services/Storage";
import * as AuthService from "../../services/Auth";

const ProtectedRoute = ({ children }) => {
  //console.log(" AuthService.isAuthenticated()",  AuthService.isAuthenticated());

  if(AuthService.isAuthenticated() !== true){
    storeData("from_cart", true);
  }

  return (AuthService.isAuthenticated() === true
    ? children
    : <Navigate to="/login" />);

};


export default ProtectedRoute;
