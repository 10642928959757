import React from "react";

import "./UiGiftCard.scss";

const UiGiftCard = (props) => {
  return (
    <button className="product-add-gift" onClick={props.onClick}>
      <div
        className={"product-add-gift-image"}
        style={{ backgroundImage: "url(" + props.item.image + ")" }}
      >
        <div className="product-add-gift-price">
          <p>+ {props.item.price} ₽</p>
        </div>
      </div>
      <h3>{props.item.title}</h3>
    </button>
  );
};
export default UiGiftCard;
