import React from "react";

import { ReactComponent as SvgClose } from "../../assets/alert/close.svg";
import { ReactComponent as SvgImageTitle } from "../../assets/card/image-title.svg";

import UiBtnColor from "../buttons/UiBtnColor";
import UiModal from "./UiModal";

import "./UiModal.scss";

const UiPromoAlertCard = (props) => {
  return (
    <UiModal show={props.modalOpen}>
      <div className="alert">
        <button className="modal-close-btn" onClick={props.modalClose}>
          <SvgClose className="modal-close-btn-icon" />
        </button>
        <div className="alert-body">
          <SvgImageTitle className="alert-icon-greeting" />
          <h3>Создай уникальную открытку ко Дню Матери!</h3>
          <p></p>
          <div className="alert-button">
            <UiBtnColor
              color="pink"
              text="Создать"
              onClick={() => window.location.href = "/promo" }
            />
          </div>
        </div>
      </div>
    </UiModal>
  );
};
export default UiPromoAlertCard;
