import Env from "../../services/Env";
import {
    getList, getMenuList, 
    getSubListById,
    getCategoryByTitle,
    getCategoryGroupByTitle,
    makeCategory, updateCategory,
    getTopMenuList
} from "../../services/Category";

import { storeData, retrieveData, safelyParseJSON } from "../../services/Storage";

const moduleName = "category";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_SUB_CATEGORY = `${moduleName}/SET_SUB_CATEGORY`;
const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
    list: [],
    subCategory: null,
    error: false,
    errorType: 3,
    isFetching: false,

}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_LIST:
            return { ...state, list: payload }
        case SET_SUB_CATEGORY:
            return { ...state, subCategory: payload }
        case SET_ERROR:
            return { ...state, error: payload }
        case SET_ERROR_TYPE:
            return { ...state, errorType: payload }

        case SET_API_REQUEST:
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}


export const setList = (value) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LIST,
            payload: value
        });
    } catch (error) {
        console.error(error);
    }
}

export const getCategoryListByTitle = (_title) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        return getCategoryByTitle(_title).then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_SUB_CATEGORY,
                    payload: res.response?.catalog
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const getCategoryListGroupByTitle = (_title) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        return getCategoryGroupByTitle(_title).then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_SUB_CATEGORY,
                    payload: res.response?.catalog
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}





export const getSubCategory = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        return getSubListById(_id).then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_SUB_CATEGORY,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const getTopMenuCategoryList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        return getTopMenuList().then((res) => {
          
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const getMenuCategoryList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        return getMenuList().then((res) => {
          
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const getCategoryList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        return getList().then((res) => { 
            if (res.response) {
                dispatch({
                    type: SET_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const addCategory = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return makeCategory(getState().login.user.api_token, _data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getCategoryList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const update = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return updateCategory(getState().login.user.api_token, _data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getCategoryList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

