import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";

import HomePage from "../pages/home/HomePage";

const CatalogPage = React.lazy(() => import("../pages/catalog/CatalogPage"));

const SearchPage = React.lazy(() => import("../pages/catalog/SearchPage"));

const LoginPage = React.lazy(() => import("../pages/login/LoginPage"));
const ProfilePage = React.lazy(() => import("../pages/profile/ProfilePage"));

const OrderPage = React.lazy(() => import("../pages/order/OrderPage"));
const UserOrderPage = React.lazy(() => import("../pages/order/UserOrderPage"));
const UserPayPage = React.lazy(() => import("../pages/order/UserPayPage"));
const OrderSuccessPage = React.lazy(() =>
  import("../pages/order/OrderSuccessPage")
);

const DeliveryPage = React.lazy(() => import("../pages/static/DeliveryPage"));
const ContactsPage = React.lazy(() => import("../pages/static/ContactsPage"));
const AboutPage = React.lazy(() => import("../pages/static/AboutPage"));
const CorpPage = React.lazy(() => import("../pages/static/CorpPage"));
const ReviewsPage = React.lazy(() => import("../pages/reviews/ReviewsPage"));
const VacanciesPage = React.lazy(() => import("../pages/static/VacanciesPage"));
const SubscribePage = React.lazy(() => import("../pages/static/SubscribePage"));

const CatalogGroupPage = React.lazy(() =>
  import("../pages/catalog/CatalogGroupPage")
);

const NotFoundPage = React.lazy(() => import("../pages/notfound/NotFoundPage"));

function AppNavigator() {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/filter/:price" element={<HomePage />} />
        <Route path="/quiz" element={<HomePage />} />
        <Route path="/promo" element={<HomePage />} />

        <Route path="/product/:title" element={<HomePage />} />

        <Route path="/catalog" element={<CatalogPage />} />
        <Route path="/catalog/product/:title" element={<CatalogPage />} />
        <Route path="/catalog/filter/:price" element={<CatalogPage />} />

        <Route path="/catalog/:name" element={<CatalogPage />} />
        <Route path="/catalog/:name/:title" element={<CatalogPage />} />
        <Route path="/catalog/:name/filter/:price" element={<CatalogPage />} />

        <Route path="/group/:name" element={<CatalogGroupPage />} />
        <Route path="/group/:name/:title" element={<CatalogGroupPage />} />

        <Route path="/search/:name" element={<SearchPage />} />
        <Route path="/search/:name/product/:title" element={<SearchPage />} />

        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order"
          element={
            <ProtectedRoute>
              <OrderPage />
            </ProtectedRoute>
          }
        />
        <Route path="/form/:id" element={<UserOrderPage />} />
        <Route path="/pay/:id" element={<UserPayPage />} />

        <Route path="/order/success" element={<OrderSuccessPage />} />

        <Route path="/delivery" element={<DeliveryPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/policy" element={<CorpPage />} />
        <Route path="/reviews" element={<ReviewsPage />} />
        <Route path="/vacancies" element={<VacanciesPage />} />
        <Route path="/subscribe" element={<SubscribePage />} />

        <Route path="*" element={<NotFoundPage />} status={404} />
      </Routes>
    </Suspense>
  );
}

export default AppNavigator;
