import React from "react";

import { ReactComponent as SvgClock } from "../../assets/about/clock.svg";
import { ReactComponent as SvgDelivery } from "../../assets/about/delivery.svg";
import { ReactComponent as SvgFlower } from "../../assets/about/flower.svg";
import { ReactComponent as SvgImage } from "../../assets/about/image.svg";
import { ReactComponent as SvgPhone } from "../../assets/about/phone.svg";
import { ReactComponent as SvgPhoto } from "../../assets/about/photo.svg";
import { ReactComponent as SvgStar } from "../../assets/about/star.svg";

import "./UiAboutCard.scss";

const UiAboutCard = (props) => {
  return (
    <div className={"about-info-plus" + (props.center ? " center" : "")}>
      <h3>ПОЧЕМУ МЫ - ЛУЧШИЕ?</h3>
      <div className="plus-list">
        <div className="plus-list-item">
          <div className="plus-list-item-image">
            <SvgStar width={22} height={22} />
          </div>
          <p>Удобный сайт для оформления заказа с безопасной оплатой</p>
        </div>
        <div className="plus-list-item">
          <div className="plus-list-item-image">
            <SvgPhoto width={21} height={21} />
          </div>
          <p>
            Перед отправкой пришлем фото, после одобрения отправим в доставку
          </p>
        </div>
        <div className="plus-list-item">
          <div className="plus-list-item-image">
            <SvgFlower width={20} height={20} />
          </div>
          <p>
            Подпишем от вас открытку, и сохраним анонимность в нужном случае
          </p>
        </div>
        <div className="plus-list-item">
          <div className="plus-list-item-image">
            <SvgDelivery width={24} height={24} />
          </div>
          <p>У нас собственный штат курьеров, осуществляющих доставку</p>
        </div>
        <div className="plus-list-item">
          <div className="plus-list-item-image">
            <SvgClock width={20} height={20} />
          </div>
          <p>
            Доставляем букет в течение часа после оформления, или в точно
            согласованное с вами время
          </p>
        </div>
        <div className="plus-list-item">
          <div className="plus-list-item-image">
            <SvgPhone width={20} height={20} />
          </div>
          <p>
            Если вы не знаете адрес получателя, позвоним и договоримся о
            доставке по удобному адресу
          </p>
        </div>
      </div>
    </div>
  );
};
export default UiAboutCard;
