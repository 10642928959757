import React from "react";

import demoVideo from "../../../assets/demo/demo_video.mp4";

import UiIcon from "../../../components/icon/UiIcon";

import "./UiHomeQuizButton.scss";

const UiHomeQuizButton = (props) => {
  return (
    <div className="home-title-video-wrap">
      <video className="home-title-video" autoPlay loop muted>
        <source
          src={demoVideo}
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
      </video>
      <div className="home-title-dots"></div>
      <div className="home-title-links">
        <div className="home-title-link">
          <h3>Не получается определиться с выбором?</h3>
          <p>Мы подберем букет специально для вас!</p>
        </div>
      </div>
      <div className="home-title-video-button">
        <a className="home-title-link-button" href={"/quiz"}>
          <span>Подобрать букет + Бонус</span>
          <div className="home-title-link-arrow">
            <UiIcon fill="blue" icon="arrow-right" size={20} />
          </div>
        </a>
      </div>
    </div>
  );
};
export default UiHomeQuizButton;
