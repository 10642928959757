export default {
  STORE_KEY: 'a56z0fzrNpl^2',
  //Dev
  API_URL_DEV: 'https://flowery.devkot.ru/server/public/api/v1',

  HOST_URL: 'https://dev.flowery-lavka.ru/',
  API_URL: 'https://dev.flowery-lavka.ru/server/public/api/v1',
  API_URL_NO_SSL: 'http://dev.flowery-lavka.ru/server/public/api/v1',
  PUBLIC_URL: 'https://dev.flowery-lavka.ru/server/public/uploads/',


  //Prod
  //HOST_URL: 'https://flowery-lavka.ru/',
  //API_URL: 'https://flowery-lavka.ru/server/public/api/v1',
  //API_URL_NO_SSL: 'http://flowery-lavka.ru/server/public/api/v1',
  //PUBLIC_URL: 'https://flowery-lavka.ru/server/public/uploads/',
  //STORAGE_URL: '',
 
  getHeaders: function () {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
  }
}; 