import Env from "../../services/Env";
import { saveUser, loadUser, logout } from "../../services/Auth";
import {
    regUser, checkLogin, confirmUser,
    getUser, getUserNotification, getUserRating,
    readUserNotification, getUsers, getBankInfo,
    addNotification,  editUser, registerMaxmaUser
} from "../../services/User";

import { storeData, retrieveData, safelyParseJSON } from "../../services/Storage";
import { getImage, convertToList, reorderBasket, increaseBasket } from "../../utilities/storage";
 

const moduleName = "login";

const SET_USER = `${moduleName}/SET_USER`;
const SET_USERS = `${moduleName}/SET_USERS`;
const SET_USER_NOTIFICATIONS = `${moduleName}/SET_USER_NOTIFICATIONS`;
const SET_USER_RATING = `${moduleName}/SET_USER_RATING`;
const SET_TABLES_LIST = `${moduleName}/SET_TABLES_LIST`;

const SET_BASKET = `${moduleName}/SET_BASKET`;

const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;

const SET_BANK_INFO = `${moduleName}/SET_BANK_INFO`;
const SET_USER_INFO = `${moduleName}/SET_USER_INFO`;

const SET_CONFIRM_SHOW = `${moduleName}/SET_CONFIRM_SHOW`;

const SET_CONFIRM_FUNCTION = `${moduleName}/SET_CONFIRM_FUNCTION`;
const SET_ALERT_SHOW = `${moduleName}/SET_ALERT_SHOW`;
const SET_ALERT_TITLE = `${moduleName}/SET_ALERT_TITLE`;
const SET_ALERT_TEXT = `${moduleName}/SET_ALERT_TEXT`;
const SET_ALERT_BTN = `${moduleName}/SET_ALERT_BTN`;
const SET_LOADER_SHOW = `${moduleName}/SET_LOADER_SHOW`;

const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;
const REGISTER = `${moduleName}/REGISTER`;

const defaultState = {
    user: null,
    userNotifications: [],
    userRating: [],
    usersList: [],

    basketList: [],

    tablesList: [],

    bankInfo: null,
    userInfo: null,
    error: false,

    confirmShowed: false,
    confirmFunc: null,

    loaderShowed: false,
    alertShowed: false,
    alertTitle: "Внимание",
    alertText: "",
    alertButton: "Ok",

    errorType: 3,
    isFetching: false,

}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_USER:
            return { ...state, user: payload }
        case SET_USERS:
            return { ...state, usersList: payload }
        case SET_USER_NOTIFICATIONS:
            return { ...state, userNotifications: payload }
        case SET_USER_RATING:
            return { ...state, userRating: payload }
        case SET_TABLES_LIST:
            return { ...state, tablesList: payload }

        case SET_BASKET:
            return { ...state, basketList: payload }


        case SET_ERROR:
            return { ...state, error: payload }
        case SET_ERROR_TYPE:
            return { ...state, errorType: payload }

        case SET_BANK_INFO:
            return { ...state, bankInfo: payload }
        case SET_USER_INFO:
            return { ...state, userInfo: payload }


        case SET_CONFIRM_SHOW:
            return { ...state, confirmShowed: payload }
        case SET_CONFIRM_FUNCTION:
            return { ...state, confirmFunc: payload }

        case SET_ALERT_SHOW:
            return { ...state, alertShowed: payload }
        case SET_ALERT_TITLE:
            return { ...state, alertTitle: payload }
        case SET_ALERT_TEXT:
            return { ...state, alertText: payload }
        case SET_ALERT_BTN:
            return { ...state, alertButton: payload }

        case SET_LOADER_SHOW:
            return { ...state, loaderShowed: payload }
            

        case SET_API_REQUEST:
            console.log('fetching API request', payload)
            return { ...state, isFetching: payload }
        case REGISTER:
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}



export const changeConfirmState = (value = true, text = "", func = null) => async (dispatch) => {
    try {

        dispatch({
            type: SET_CONFIRM_SHOW,
            payload: value
        });
        dispatch({
            type: SET_CONFIRM_FUNCTION,
            payload: func
        });

        dispatch({
            type: SET_ALERT_TITLE,
            payload: "Внимание"
        });
        dispatch({
            type: SET_ALERT_TEXT,
            payload: text
        });


    } catch (error) {
        console.error(error);
    }
}


export const changeAlertState = (value = true, text = "", title = "Внимание", button = "Ok") => async (dispatch) => {
    try {

        dispatch({
            type: SET_ALERT_SHOW,
            payload: value
        });
        dispatch({
            type: SET_ALERT_TITLE,
            payload: title
        });
        dispatch({
            type: SET_ALERT_TEXT,
            payload: text
        });
        dispatch({
            type: SET_ALERT_BTN,
            payload: button
        });

    } catch (error) {
        console.error(error);
    }
}

export const changeLoaderState = (value = true) => async (dispatch) => {
    try {

        dispatch({
            type: SET_LOADER_SHOW,
            payload: value
        });
 
    } catch (error) {
        console.error(error);
    }
}


export const setUser = (value) => async (dispatch) => {
    try {
        dispatch({
            type: SET_USER,
            payload: value
        });
        saveUser(value);
    } catch (error) {
        console.error(error);
    }
}

export const logoutUser = () => async (dispatch) => {
    try {
        logout();
        return dispatch({
            type: SET_USER,
            payload: null
        });

    } catch (error) {
        console.error(error);
    }
}

const getStoredUserLS = () =>  {
    let value = retrieveData(SET_USER); 
    if (value) return value; else return null;
}


export const getStoredUser = () => async (dispatch, getState) => {
    try {

        let user = loadUser();
        
        dispatch({
            type: SET_USER,
            payload: user
        });
      
        if (user) {
            dispatch(getUserInfo(user.api_token, user.id))
        }

        return loadUser();
    } catch (error) {
        console.error(error);
    }

}

const getStoredBasketLS = () =>  {
    let value = retrieveData(SET_BASKET); 
    if (value) return value; else return [];
}


export const addUserBasket = (value, _reorder = false ) => async (dispatch, getState) => {
    try {
        let basket = getStoredBasketLS(); 
        if(typeof value.image == 'undefined') value.image = getImage(value.imgs);
        basket.push(value);
        if(_reorder) basket = reorderBasket(basket);  
        dispatch({
            type: SET_BASKET,
            payload: basket
        });
        storeData(SET_BASKET, basket);
    } catch (error) {
        console.error(error);
    }
}

export const changeUserBasket = (_id, _v) => async (dispatch, getState) => {
    try { 
        let basket = getStoredBasketLS(); 
        basket = increaseBasket(basket, _id, _v);
        dispatch({
            type: SET_BASKET,
            payload: basket
        }); 
        storeData(SET_BASKET, basket);
    } catch (error) {
        console.error(error);
    }
}

export const clearUserBasket = () => async (dispatch, getState) => {
    try { 
        storeData(SET_BASKET, []);
    } catch (error) {
        console.error(error);
    }
}

export const getStoredBasket = () => (dispatch, getState) =>  {
        let value = retrieveData(SET_BASKET);  
        if (value) return value; else return [];
}


export const getStoredUserBasket = (_reorder = false) => async (dispatch, getState) => {
    try {
        let value = retrieveData(SET_BASKET); 
    
        if (value) {
            if(_reorder) value = reorderBasket(value);  
            dispatch({
                type: SET_BASKET,
                payload: value
            }); 
        }
    } catch (error) {
        console.error(error);
    }
}

export const checkInUserBasket = (id) => (dispatch, getState) => {
    let f = false;
    let basket = getStoredBasketLS(); 
    basket.map((item) => {
        if (item.id == id) f = true;
    })
    return f;
}



export const setErrorType = (value) => async (dispatch) => {
    try {
        dispatch({
            type: SET_ERROR_TYPE,
            payload: value
        });

    } catch (error) {
        console.error(error);
    }
}

export const loginTestUser = () => async (dispatch, getState) => {
    try {
                let _user = {
                    id: 1,
                    phone: "71111111111",
                    name: "Oleg"
                }
                saveUser(_user);
                return _user;
   
    } catch (error) {
        console.error(error);
    }

}




export const registerMaxma = (_user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

       return registerMaxmaUser(_user).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });

            if (res.status == 200) { 
               
                return res.response;
            } else {
                return null;
            }


        })
    } catch (error) {
        console.error(error);
    }

}

export const loginUser = (phone, code) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

       return checkLogin(phone, code).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });

            if (res.status == 200) { 
                let _user = res.response;
                saveUser(_user);
                return _user;
            } else {
                return null;
            }


        })
    } catch (error) {
        console.error(error);
    }

}

export const registerUser = (
    _phone
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return regUser(
            _phone
        ).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}
  
export const updateUser = ( _data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        return editUser(_data).then((res) => {
            if (res.response) {
               
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}



export const getUserInfo = (
    _api, _id
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getUser(
            _api,
            _id,
            false
        ).then((res) => {
            if (res.response) {
                let _user = res.response;

                saveUser(_user);
                dispatch({
                    type: SET_USER,
                    payload: _user
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const showUserInfo = (
    _api, _id
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getUser(
            _api,
            _id,
            true
        ).then((res) => {
            if (res.response) {
                let _user = res.response;


                dispatch({
                    type: SET_USER_INFO,
                    payload: _user
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const getUsersList = () => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });


            return getUsers(getState().login.user.api_token).then((res) => {
                if (res.response) {
                    let _data = res.response;


                    dispatch({
                        type: SET_USERS,
                        payload: _data
                    });
                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }


}




export const addNotify = (
    _type, _value, _text
) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return addNotification(getState().login.user.api_token, _type, _value, _text).then((res) => {
            if (res.response) {

            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }


}

