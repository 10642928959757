import React from "react";

import UiIcon from "../icon/UiIcon";

import "./UiBtnTopScroll.scss";

const UiBtnTopScroll = (props) => {
  return (
    <button
      className={"btn-top-scroll" + (props.active ? " active" : "")}
      disabled={!props.active}
      onClick={props.onClick}
    >
      <UiIcon fill="white" icon="arrow-up" size={20} />
    </button>
  );
};
export default UiBtnTopScroll;
